// import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
// import 'firebase/compat/functions'
// import 'firebase/compat/analytics'
// import 'firebase/compat/auth'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, httpsCallable } from 'firebase/functions'

let config = {}
console.log(`[APP] App is running in ${process.env.VUE_APP_NODE_ENV} mode`)

if (process.env.VUE_APP_NODE_ENV === 'prod') {
  // Production mode
  config = {
    apiKey: 'AIzaSyDhr9NVDnHEBNAN3QJKNrPM3cjx4J_Xr_8',
    authDomain: 'varycore-v1-prod.firebaseapp.com',
    projectId: 'varycore-v1-prod',
    storageBucket: 'varycore-v1-prod.appspot.com',
    messagingSenderId: '677424388766',
    appId: '1:677424388766:web:f6f372e1a272b83bd0798f',
    measurementId: 'G-LSMZM56H84'
  }
} else {
  // Development mode
  config = {
    apiKey: 'AIzaSyAKtwRAquVpUetfqE3Ya1LPoXE81Ngglsg',
    authDomain: 'varycore-v1-dev.firebaseapp.com',
    projectId: 'varycore-v1-dev',
    storageBucket: 'varycore-v1-dev.appspot.com',
    messagingSenderId: '369969774529',
    appId: '1:369969774529:web:6b47302efb49ffbf587940'
  }
}

// Firebase init goes here
const app = initializeApp(config)
const funcs = getFunctions(app, 'europe-west2')

// !firebase.apps.length && firebase.initializeApp(config)
if (process.env.VUE_APP_NODE_ENV === 'prod') getAnalytics()

// Firebase utils
const auth = {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword
}

const functions = {
  httpsCallable: (funcName = '') => httpsCallable(funcs, funcName)
}
// const storage = firebase.storage()
// const functions = firebase.functions()
// const functions = firebase.app().functions('europe-west2') // Set the functions region to the correct one
// const db = firebase.firestore()

// Set up development modes
if (process.env.VUE_APP_LOCAL_DEVELOPMENT === 'enabled') {
  console.info('IMPORTANT: Running local FUNCTIONS and local DATABASE mode')

  // functions.useEmulator('localhost', 5001) // Single computer development
  // functions.useEmulator('10.0.0.254', 5001) // Local network development @ home
  // functions.useEmulator('192.168.50.97', 5001) // Local network development @ work

  // auth.useEmulator('http://localhost:9099') // Single computer development
  // auth.useEmulator('http://10.0.0.254:9099') // Local network development @ home
  // auth.useEmulator('http://192.168.50.97:9099') // Local network development @ work

  // db.settings({
  //   // host: '0.0.0.0:8081', // Single computer development
  //   // host: '10.0.0.254:8081', // Local network development @ home
  //   host: '192.168.50.97:8081', // Local network development @ work
  //   ssl: false
  // })
}

// Firebase collections (only for snapshots - all others are fetched via API)
// const snapsCollection = db.collection('snaps')

const fb = {
  app,
  // snapsCollection,
  // config,
  // firebase,
  // eslint-disable-next-line import/prefer-default-export
  auth,
  // storage,
  functions
  // db
}

export default fb
